import * as React from "react"

import { Button } from "../ui/button";

import "@flaticon/flaticon-uicons/css/all/all.css";
import { cn } from "../../lib/utils";
import { Deployment } from "app-models-specification-ts/user/Deployment";


//import { XUser } from "customapp-models-specification-ts/core/XUser";

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip"

import type { Instance } from "common-models-ts";
import { useEffect, useState } from "react";
import { User } from "app-models-specification-ts/user/User";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";



interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
}

interface SidebarSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    header: string;
    initialHideChildren?: boolean;
    mode?: SideBarMode;
    compactHeader?: string;
}

export enum SideBarMode {
    Full = 'full',
    Compact = 'compact',
    Hidden = 'hidden',
}

export enum ScreenMode {
    Full = 'full',
    Normal = 'normal',
}

interface SidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
    flatIcon: string;
    label: string;
    selected?: boolean;
    action?: string;
    disabled?: boolean;
    mode?: SideBarMode;
}

function SidebarSection({ className, header, children, initialHideChildren, mode, compactHeader }: SidebarSectionProps) {
    const [showChildren, setShowChildren] = useState(!(initialHideChildren !== undefined && initialHideChildren));
    const iconClass = showChildren ? "minus" : "plus"
    if (mode === SideBarMode.Hidden) {
        return (<></>);
    }

    return (
        <div className={`px-0 pt-6 ${className}`}>
            <h2 className="mb-2 px-3.5 text-sm tracking-tight text-gray-400 text-left">
                {mode === SideBarMode.Compact
                    ? compactHeader?.toUpperCase()
                    : <>
                        {header.toUpperCase()}
                        <i className={`fi fi-sr-square-${iconClass} float-right cursor-pointer`} onClick={() => setShowChildren(!showChildren)} />
                    </>
                }
            </h2>
            <div className="space-y-1">
                {showChildren && children}
            </div>
        </div>
    )
}

function SidebarItem({ className, flatIcon, label, selected, action, disabled, hidden, mode }: SidebarItemProps) {
    const variant = selected ? "secondary" : "ghost";
    if (hidden || mode === SideBarMode.Hidden) {
        return (<></>);
    }

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className="w-full">
                    <>
                        <Link to={action!}>
                            <Button variant={variant} className={`${className} w-full justify-start rounded-none`} disabled={disabled}>
                                <i className={`fi fi-${flatIcon}`} />
                                {mode === SideBarMode.Full && <p className="ml-4 mb-1">{label}</p>}
                            </Button>
                        </Link>
                    </>
                </TooltipTrigger>
                <TooltipContent>
                    <p>{label}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

interface SidebarActionProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: () => void;
    mode: SideBarMode;
}

function SidebarAction({ onClick, mode }: SidebarActionProps) {
    const iconClassName = mode === SideBarMode.Full ? "fi-br-angle-left" : "fi-br-angle-right";
    return (
        // Post to the same page to change the sidebar mode
        <>
            <input type="hidden" name="sideBarMode" value={mode} />
            <Button onClick={onClick} variant={"secondary"} className={`w-6 h-8 fixed bottom-2 left-2`}>
                <i className={`fi ${iconClassName}`} />
            </Button>
        </>
    )
}

interface FullScreenActionProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: () => void;
    screenMode: ScreenMode;
}

function FullScreenAction({ onClick, screenMode }: FullScreenActionProps) {
    const iconClassName = screenMode === ScreenMode.Normal 
        ? "fi-rr-arrow-up-right-and-arrow-down-left-from-center" : "fi-sr-cross";
    return (
        // Post to the same page to change the screenMode mode
        <>
            <input type="hidden" name="screenMode" value={screenMode} />
            <Button onClick={onClick} variant={"secondary"} className={`w-6 h-8 fixed bottom-12 left-2`}>
                <i className={`fi ${iconClassName}`} />
            </Button>
        </>
    )
}

//<i class="fi fi-rr-arrow-up-right-and-arrow-down-left-from-center"></i>
//             <div className="pl-2 py-2">
//{mode === SideBarMode.Full ? <img src="/logo.png" width="140" /> : <img src="/cloud.png" width="35" />}
//</div>


export function SideBar({ className }: SidebarProps) {
    const global = useSelector((state: any) => state.global);
    const { me, userPrefsCookie, userToken, selected } = global;
    const { deployment: selectedDeployment } = selected;

    const [mode, setMode] = useState(userPrefsCookie?.sideBarMode || SideBarMode.Full);
    const [screenMode, setScreenMode] = useState(userPrefsCookie?.screenMode || ScreenMode.Normal);

    const width = mode === SideBarMode.Full ? "w-[220px]" : "w-[50px]";

    const prefix = selectedDeployment ? `/deployments/${selectedDeployment.id}` : ''
    return (
        <div className={cn("flex flex-col min-h-screen py-4 pl-0 text-gray-300 bg-primary", width, className)}>
            <SidebarAction
                onClick={() => setMode(mode === SideBarMode.Full ? SideBarMode.Compact : SideBarMode.Full)}
                mode={mode}
            />
            <SidebarSection
                header="Organization"
                compactHeader="O"
                mode={mode}
            >
                <SidebarItem
                    flatIcon="rr-object-group"
                    label="Deployments"
                    action={"/deployments"}
                    mode={mode}
                />
            </SidebarSection>
            <SidebarSection
                header="X Setup"
                compactHeader="X"
                mode={mode}
            >
                <SidebarItem
                    flatIcon="rr-crown"
                    label="Influencers"
                    action={`${prefix}/workbench/xinfluencers`}
                    //disabled={!deployment}
                    mode={mode}
                />
                <SidebarItem
                    flatIcon="rr-portrait"
                    label="Users"
                    action={`${prefix}/workbench/xusers`}
                    //disabled={!deployment}
                    mode={mode}
                />
                <SidebarItem
                    flatIcon="rr-picture"
                    label="Media"
                    action={`${prefix}/workbench/xmedia`}
                    //disabled={!deployment}
                    mode={mode}
                />
                <SidebarItem
                    flatIcon="rr-blog-pencil"
                    label="Post Templates"
                    action={`${prefix}/workbench/xposttemplates`}
                    //disabled={!deployment}
                    mode={mode}
                />
            </SidebarSection>
            <SidebarSection
                header="Settings"
                compactHeader="S"
                mode={mode}
            >
                <SidebarItem
                    flatIcon="rr-key"
                    label="Tokens"
                    action={`${prefix}/workbench/xapppolicies`}
                    //disabled={!deployment}
                    mode={mode}
                />
                <SidebarItem
                    flatIcon="brands-telegram"
                    label="Telegram"
                    action={`${prefix}/workbench/xusers`}
                    //disabled={!deployment}
                    mode={mode}
                />
            </SidebarSection>
            <SidebarSection
                header="Timeline"
                compactHeader="T"
                mode={mode}
            >
                <SidebarItem
                    flatIcon="brands-twitter-alt"
                    label="Watch"
                    action={`${prefix}/workbench/xapppolicies`}
                    //disabled={!deployment}
                    mode={mode}
                />
            </SidebarSection>
        </div>
    )
}


/*
            <FullScreenAction
                onClick={() => setScreenMode(screenMode === ScreenMode.Full ? ScreenMode.Normal : ScreenMode.Full)}
                screenMode={screenMode}   
            />
*/