import { Link } from "react-router-dom";
import { cn } from "../../lib/utils";

function House() {
    return (
        <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
        </svg>
    );
}

function Arrow() {
    return (
        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
        </svg>
    );
}

export interface BreadcrumbElement {
    label: string;
    link: string;
}

export interface BreadcrumbsProps {
    elements: Array<BreadcrumbElement>;
}

export default function Breadcrumbs({className, elements}: React.HTMLAttributes<HTMLElement> & BreadcrumbsProps) {
    // Get the first element in the array
    const home = elements.length >= 0 ? elements[0] : undefined;
    // Get the last element in the array
    const current = elements.length >= 2 ? elements[elements.length - 1] : undefined;
    // Get the elements in between the first and last elements
    elements = elements.length >= 2 ? elements.slice(1, elements.length - 1) : [];

    const homeComponent = home && (
        <li className="inline-flex items-center">
            <Link
                to={home.link}
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
                <House />
                {home.label}
            </Link>
        </li>
    );

    const currentComponent = current && (
        <li aria-current="page">
            <div className="flex items-center">
                <Arrow />
                <span 
                    className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400"
                >
                    {current.label}
                </span>
            </div>
        </li>
    );

    const elementsComponent = elements.map((element, index) => (
        <li key={index}>
            <div className="flex items-center">
                <Arrow />
                <Link 
                    to={element.link} 
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                >
                    {element.label}
                </Link>
            </div>
        </li>
    ));

    return (
        <nav className={cn("flex", className)} aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                {homeComponent}
                {elementsComponent}
                {currentComponent}
            </ol>
        </nav>
    );
}
