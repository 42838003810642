
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Instance } from 'common-models-ts'
import { set } from 'date-fns'
import { Deployment } from 'app-models-specification-ts/user/Deployment'
import { User } from "app-models-specification-ts/user/User";

export type VariableUpdateAction = {
    key: string,
    value: any
}

export type Selected = {
    deployment?: Deployment|null|undefined,
    category?: string|null|undefined,
    instanceType?: string|null|undefined,
    instance?: Instance|null|undefined,
    instanceView?: string|null|undefined,
    relatedInstanceType?: string|null|undefined,
    relatedInstance?: Instance|null|undefined,
    relatedInstanceView?: string|null|undefined,
}

type GlobalState = {
    variables: {[key: string]: any},
    selected: Selected,
    me?: User,
    userPrefsCookie?: any,
    userToken?: any,
}

const initialState = {
    variables: {},
    selected: {},
    me: undefined,
    userPrefsCookie: undefined,
    userToken: undefined,
} as GlobalState

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateVariable: (state, action: PayloadAction<VariableUpdateAction>) => {
            const { key, value } = action.payload;
            state.variables[key] = value;
        },
        updatedSelected: (state, action: PayloadAction<Selected>) => {
            state.selected = {
                ...state.selected,
                ...action.payload,
            }
        },
        setMe: (state, action: PayloadAction<User>) => {            
            console.log("setMe", action.payload);
            state.me = action.payload;
        },
    },
})

export const { updateVariable, updatedSelected, setMe } = globalSlice.actions

export default globalSlice.reducer