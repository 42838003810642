import { configureStore } from '@reduxjs/toolkit'

import globalReducer from './slices/global';
import instancesReducer from './slices/instances';

const store = configureStore({
  reducer: {
    global: globalReducer,
    instances: instancesReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;