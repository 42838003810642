import React, { FC, useEffect, useState } from "react";

import RenderOnAuthenticated from "./components/authenticated/RenderOnAuthenticated";
import RenderOnAnonymous from "./components/authenticated/RenderOnAnonymous";
import NotAllowed from "./components/authenticated/NotAllowed";
import {User} from "app-models-specification-ts/user/User";
import {Deployment} from "app-models-specification-ts/user/Deployment";
import {EventService} from "./services/EventService";
import { CORE, CORE_LOGIC, USER, USER_LOGIC } from "./services/Services";

import { setMe } from "./redux/slices/global";

import {InstanceEditor} from "./pages/InstanceEditor";

import { Navigate, Route, Routes } from "react-router-dom";
import Deployments from "./pages/Deployments";
import Workbench from "./pages/Workbench";
import { SideBar } from "./components/application/side-bar";
import { TopBar } from "./components/application/top-bar";
import { InstanceAction, Service } from "./util/types";
import { DataService } from "./services/DataService";
import { useAppDispatch, useAppSelector } from "./redux/hooks";

const userDataService = new DataService(USER);

export const AppContainer: FC = (props) => {
    const dispatch = useAppDispatch();
    const global = useAppSelector((state: any) => state.global);
    const { me, userPrefsCookie, userToken, selected } = global;
    const { deployment } = selected;

    const getMe = async () => {
        const user = (await userDataService.get('/me')).data;
        console.log("getMe", {user});
        dispatch(setMe(user as User));
    }

    useEffect(() => {
        getMe();
    }, []);

    if(!me) {
        return <div>Loading...</div>;
    }

    // TODO: Add custom routes here (workbench routes are already added)
    return (
        <>
            <RenderOnAuthenticated>
                <div className={'flex'}>
                    <SideBar />
                    <div className="w-full flex flex-col">
                        <TopBar
                            className="bg-gblue-200 h-10 mb-2 px-4"
                        />
                        <div className={''}>
                            <Routes>
                                <Route 
                                    path="/" 
                                    element={
                                        <Navigate 
                                            to="/deployments" 
                                            replace 
                                        />
                                    } 
                                />
                                <Route path="/deployments" element={
                                    <Deployments 
                                    />}
                                />
                                <Route path="/deployments/add" element={
                                    <InstanceEditor
                                        service={USER}
                                        type={Deployment}
                                        action={InstanceAction.ADD}
                                        returnRoute="/deployments"
                                    />}
                                />
                                <Route 
                                    path="/deployments/:deploymentId" 
                                    element={
                                        <Navigate 
                                            to={`/deployments/${deployment?.id}/workbench`}
                                        />
                                    }
                                />
                                <Route
                                    path="/deployments/:deploymentId/workbench/*"
                                    element={
                                        <Workbench

                                        />
                                    }
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
                <EventService
                    serviceName={CORE_LOGIC}
                    dataServiceName={CORE}
                    deploymentMode={true}
                />
                <EventService
                    serviceName={USER_LOGIC}
                    dataServiceName={USER}
                />
            </RenderOnAuthenticated>
            <RenderOnAnonymous>
                <NotAllowed/>
            </RenderOnAnonymous>
        </>
    );
}
