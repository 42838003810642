import React, { FC, useEffect, useState } from "react";

import { InstanceAction } from "../util/types";
import { Instance } from "common-models-ts";
import { getTypeSpec } from "../custom/util/typeSpecs";
import { useForm } from "react-hook-form";
import Header from "../components/common/header";
import { Separator } from "@radix-ui/react-separator";
import { DataService } from "../services/DataService";
import { useNavigate } from "react-router-dom";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export interface InstanceEditorProps {
    service: string; // core or user
    type: typeof Instance;
    action: InstanceAction;
    returnRoute: string;
    data?: Instance;
}

const LoadingError = (error: any) => {
    console.error(error);
    return (
        <div>
            <h1>Error loading form</h1>
        </div>
    );
}

export function InstanceEditor({ service, type, action, returnRoute, data }: InstanceEditorProps) {
    const suffix = action === InstanceAction.ADD ? " - Add" : " - Edit";
    const typeSpec = getTypeSpec(type);

    const dataService = new DataService(service);

    if (!typeSpec) {
        return <LoadingError error={`TypeSpec not found for ${type.name}`} />;
    }

    const { EditorForm, editorSchema, tagLine } = typeSpec;

    type UserSchemaType = z.infer<typeof editorSchema>;
    const form = useForm<UserSchemaType>({
        resolver: zodResolver(editorSchema),
    });

    const navigate = useNavigate();

    const _data = data || {};

    const saveInstance = ((data: any) => {
        const method = action === InstanceAction.ADD ? "POST" : "PUT";
        const id = action === InstanceAction.ADD ? undefined : data.id;
        console.log(method, type.specification.url, data);
        dataService.request(method, type.specification.url!, id, data)
            .then((data) => {
                console.log("Saved", data);
                navigate(returnRoute);
            });
    });

    if (!EditorForm) {
        return <LoadingError error={`EditorForm not found for ${type.name}`} />;
    }

    return (
        <div className="relative h-full bg-white text-gray-600">
            <Header label={"Deployments" + suffix} tagline={tagLine} className="mx-4 mb-6" />
            <Separator />
            <div className="mx-4 my-4">
                <EditorForm
                    data={_data}
                    form={form}
                    onSubmit={saveInstance}
                    onCancel={() => navigate(returnRoute)}
                />
            </div>
        </div>
    );
}