import { Instance } from "common-models-ts"
import { Deployment } from "app-models-specification-ts/user/Deployment"

import { DeploymentTypeSpec } from "../typeSpecs/DeploymentTypeSpec";

export const getTypeSpec = (type: typeof Instance) => {
    switch (type) {
        case Deployment:
            return DeploymentTypeSpec;
        default:
            return null;
    }
}