import { ZodObject } from "zod";
import { Form, UseFormReturn } from "react-hook-form";

export enum ENDPOINT {
    Automation = "automations",
    Job = "jobs",
};


export enum InstanceAction {
    ADD = "add",
    EDIT = "edit",
};

export interface EditorFormProps<T> {
    data: T,
    form: UseFormReturn<any>,
    disabled?: boolean;
    relatedInstances?: RelatedInstances;
    initialUpdate?: { [key: string]: any },
    isAddNew?: boolean;
    onSubmit: (data: any) => void;
    onCancel: () => void;
}

export type RelatedInstances = { [specificationName: string]: { [id: string]: any } };


export interface TypeSpec {
    EditorForm: ({ form, data }: EditorFormProps<any>) => JSX.Element;
    editorSchema: ZodObject<any>;
    tagLine: string;
}

export enum Service {
    CORE = "core",
    USER = "user",
}
