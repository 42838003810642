import React, {FC, useEffect, useRef, useState} from "react";

// @ts-ignore
import io, {Socket} from "socket.io-client";
import {DataService} from "./DataService";
import UserService from "./UserService";
import {instancesSlice} from "../redux/slices/instances";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {InstanceListFilter} from "../model/InstanceListFilter";
import {SERVICE_MAP} from "./Services";
import { useSelector } from "react-redux";

type JSONObject = {[index: string]: any};

export interface EventServiceProps {
    serviceName: string;
    dataServiceName: string;
    deploymentMode?: boolean;
    defaultInstancesKey?: string;
    defaultInstancesIdAttribute?: string
}

const queryStringToJSON = (params: string) => {
    const pairs: string[] = params.split('&');
    const result: JSONObject = {};
    pairs.forEach((pair) => {
        const pairArray = pair.split('=');
        result[pairArray[0]] = pairArray[1];
    });
    return result;
}


const modifierFilter = (item: {[index: string]: {}}, instanceListFilter?: InstanceListFilter) => {
    if(instanceListFilter) {
        for(const _instanceListFilter of [instanceListFilter.transient, instanceListFilter.db]) {
            if (_instanceListFilter) {
                const _filter = queryStringToJSON(_instanceListFilter);
                for (const [key, value] of Object.entries(_filter)) {
                    if (item[key].toString() !== value) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
}

export const EventService: FC<EventServiceProps> = (props) => {
    const {
        serviceName,
        dataServiceName,
        deploymentMode,
        defaultInstancesKey,
        defaultInstancesIdAttribute,
    } = props;

    const dataService = new DataService(dataServiceName);

    const global = useSelector((state: any) => state.global);
    const instances = useSelector((state: any) => state.instances);
    const { me, userPrefsCookie, userToken, selected, instanceFilters } = global;
    const { deployment: selectedDeployment } = selected;

    const dispatch = useAppDispatch();

    const [socket, setSocket] = useState<Socket|undefined>();

    const instanceFiltersRef:{ [index: string]: any; } = useRef();
    instanceFiltersRef.current = instanceFilters;

    const instancesRef:{ [index: string]: any; } = useRef();
    instancesRef.current = instances;

    const connect = () => {
        if(socket) {
            socket?.close();
        }
        const socket_io_path = `/socket.io`;

        const headers: {[key:string]: string} = {
            Authorization: "Bearer " + UserService.getToken(),
        }

        // connect to both user-logic and core-logic events
        headers['x-service'] = serviceName;
        if(selectedDeployment) {
            headers['x-deployment-id'] = selectedDeployment.id as string;
        }

        const _socket = io({
            path: socket_io_path,
            extraHeaders: headers,
        });
        // @ts-ignore
        _socket.on('event', events => {
            //console.log('on event', events);
            for (const event of events) {
                onEvent(event);
            }
        });
        _socket.on('connecting', function () {
            console.info(`${socket_io_path} connecting ${serviceName}`);
        });
        _socket.on('disconnect', function () {
            console.info(`${socket_io_path} disconnect ${serviceName}`);
        });
        _socket.on('connect_failed', function() {
            console.info(`${socket_io_path} connect_failed ${serviceName}`);
        })
        _socket.on('error', function() {
            console.info(`${socket_io_path} error ${serviceName}`);
        })
        _socket.on('reconnect', function() {
            console.info(`${socket_io_path} reconnect ${serviceName}`);
        })
        _socket.on('reconnecting', function() {
            console.info(`${socket_io_path} reconnecting ${serviceName}`);
        })
        _socket.on('reconnect_failed', function() {
            console.info(`${socket_io_path} reconnect_failed ${serviceName}`);
        })
        _socket.on('connect', function () {
            console.info(`${socket_io_path} connected ${serviceName}`);
        });

        setSocket(_socket);
    }

    useEffect(() => {
        // on mount
        if(!deploymentMode) {
            connect();
        }
    }, []);

    useEffect(() => {
        // on deployment change
        if(selectedDeployment && deploymentMode) {
            connect();
        }
    }, [selectedDeployment]);

    const checkFilter = (specification: string, data: any) => {
        if(!instanceFiltersRef.current) {
            return true;
        }
        const instanceFilter = instanceFiltersRef.current[specification];
        const res = instanceFilter ? modifierFilter(data, instanceFilter) : true;
        //console.log("checkFilter", specification, res, data, instanceFilter);
        return res;
    }

    const onEvent = (event: any) => {
        console.debug('onEvent New Event:\n%s', JSON.stringify(event, null, 4));
        if(event && event.data && !event.error) {
            if(!SERVICE_MAP[serviceName]) {
                console.error('Error processing event. ' +
                    'Service Map entry not found: %s\n' +
                    'event.data.service: %s\n' +
                    'SERVICE_MAP[service]: %s\n' +
                    'SERVICE_MAP: %s\n' +
                    'event:\n%s',
                    serviceName,
                    event.data.service,
                    SERVICE_MAP[serviceName],
                    JSON.stringify(SERVICE_MAP),
                    JSON.stringify(event, null, 4)
                );
            }
            else if(event.data.service === SERVICE_MAP[serviceName]) {
                console.debug('New Event:\n%s', JSON.stringify(event, null, 4));
                const specification = event.data.service + '.' + event.data.specification;
                const url = event.data.url;
                const data = event.data;

                if (event.operation === 'CREATE') {
                    // Check the Instance Filters in case we are filtering out this create event
                    if (checkFilter(specification, data)) {
                        dispatch(instancesSlice.actions.createInstance({key: specification, data}));
                    }
                } else if (event.operation === 'UPDATE') {
                    dispatch(instancesSlice.actions.updateInstance({key: specification, data}));
                } else if (event.operation === 'DELETE') {
                    dispatch(instancesSlice.actions.removeInstance({key: specification, data}));
                } else if (event.operation === 'REFRESH') {
                    console.log("EVENT REFRESH", event);
                    dataService.get(url).then((res) => {
                        const data = res.data;
                        dispatch(instancesSlice.actions.replaceInstances({key: specification, data}));
                    })
                }
                if (event && event.error) {
                    console.error('New Event:\n%s', JSON.stringify(event, null, 4));
                }
            }
        }
    }

    return null;
}
