import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type JSONObject = {[index: string]: any};

interface InstanceCRUDPayload {
    key: string;
    data: JSONObject;
}

interface InstanceGetAllPayload {
    key: string;
    data: Array<JSONObject>;
    pagination?: Pagination;
}

interface InstanceSortPayload {
    key: string;
    direction: number;
    attributeName: string
}

interface InstanceClearPayload {
    key: string;
}

interface Pagination {
    size: number,
    page: number,
    total: number,
}

interface Instances {
    [key: string]: {
        instances: Array<any>;
        pagination?: Pagination;
        filter?: string;
    }
}

const initialState: Instances = {};

const findInstanceIndex = (array: Array<any&{id:string}>, data: any&{id:string}) => {
    return array?.findIndex((value: any&{id:string}, index: number, obj: any[]) => {
        return value.id === data.id;
    })
}

export const instancesSlice = createSlice({
    name: 'instances',
    initialState,
    reducers: {
        createInstance(state, action: PayloadAction<InstanceCRUDPayload>) {
            console.log("createInstance", action.payload);
            const {key, data} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            // might need to replace existing if it's an event at time of creation
            const index = findInstanceIndex(state[key].instances, data);
            if(index >= 0) {
                state[key].instances[index] = {
                    ...state[key].instances[index],
                    ...data,
                }
            }
            else {
                state[key].instances.push(data);
            }
        },
        updateInstance(state, action: PayloadAction<InstanceCRUDPayload>) {
            console.log("updateInstance", action.payload);
            const {key, data} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            const index = findInstanceIndex(state[key].instances, data);
            if(index >= 0) {
                state[key].instances[index] = {
                    ...state[key].instances[index],
                    ...data,
                }
            }
        },
        removeInstance(state, action: PayloadAction<InstanceCRUDPayload>) {
            const {key, data} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            const index = findInstanceIndex(state[key].instances, data);
            state[key]?.instances.splice(index, 1);
        },
        sortInstances(state, action: PayloadAction<InstanceSortPayload>) {
            const {key, direction, attributeName} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            state[key].instances = state[key].instances.sort((a: any, b: any) => (a[attributeName] > b[attributeName]) ? direction : -1*direction);
        },
        clearAllInstancesExcept(state, action: PayloadAction<InstanceClearPayload>) {
            const {key} = action.payload;
            state = {
                [key]: state[key]
            }
        },
        replaceInstances(state, action: PayloadAction<InstanceGetAllPayload>) {
            console.log("replaceInstances", action.payload);
            const {key, data, pagination} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            state[key].instances = data;
            state[key].pagination = pagination;
        },
        /*
        unsetNew(state, action: PayloadAction<InstanceCRUDPayload>) {
            const {key, data} = action.payload;
            if(!state[key]) state[key] = {instances: []};
            const index = findInstanceIndex(state[key].instances, data);
            state[key].instances[index] = {...state[key].instances[index], new: false}; // stop the UI animation
        },
        */
        clearAllInstances(state) {
            for(const k of Object.keys(state)) {
                if(k !== 'user.Deployment') { // Don't clear deployments TODO: put this in custom
                    delete state[k];
                }
            }
        },
    },
})

export const {createInstance, updateInstance, removeInstance, sortInstances, clearAllInstancesExcept, replaceInstances, clearAllInstances} = instancesSlice.actions;

export default instancesSlice.reducer;
