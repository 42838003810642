import {cn} from "../../lib/utils";
import Breadcrumbs from "../common/breadcrumbs";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import { ScreenMode } from "./side-bar";
import { useSelector } from "react-redux";


export interface TopBarProps {
}

export function TopBar ({className, ...props}: React.HTMLAttributes<HTMLElement> & TopBarProps) {
    const global = useSelector((state: any) => state.global);
    const { me, userPrefsCookie, userToken, selected } = global;
    const { deployment, category, instanceType, instance } = selected;

    const getBreadcrumbElements = () => {
        const elements = [];

        let link = "/deployments";
        elements.push({label: "Deployments", link});

        if(deployment) {
            link += `/${deployment.id}`;
            elements.push({label: deployment.name, link});
            if(category) {
                link += `/workbench/${category}`;
                elements.push({label: category, link});
                if(instanceType) {
                    link += `/${instanceType}`;
                    elements.push({label: instanceType, link});
                    if(instance) {
                        link += `/${instance.name}`;
                        elements.push({label: instanceType, link});
                    } 
                }
            }
        }

        return elements;
    }

    const breadcrumbElements = getBreadcrumbElements();

    if(userPrefsCookie?.screenMode === ScreenMode.Full) {
        return (
            <>
                <Breadcrumbs elements={breadcrumbElements} className={"absolute ml-4 mt-1"} />
                <div className="mt-6"></div>
            </>
        );
    }
    return (
        <div className={cn("w-full h-16 flex text-gray-600", className)}>

            <Breadcrumbs elements={breadcrumbElements} />

            <div className="ml-auto"></div>
            {deployment &&
                <>
                    <i className="mr-2 fi fi-rr-globe my-2.5" />
                    <div className="mx-2 my-2">
                        {deployment.name}
                    </div>
                </>
            }

            <Popover>
                <PopoverTrigger asChild>
                    <div className="flex cursor-pointer">
                        <i className="ml-10 mr-2 fi fi-rr-user my-2.5" />
                        <div className="ml-2 mr-6 my-2">
                            {me.firstName}
                        </div>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-[250px] min-w-[250px] p-0">
                    <div className="py-2 px-4 flex text-gray-400">
                        <i className="mt-[2px] mr-4 fi fi-rr-user" />
                        <div>
                            {`${me.firstName} ${me.lastName}`}
                        </div>
                    </div>
                    <div className="py-2 px-4 flex text-gray-400">
                        <i className="mt-[2px] mr-4 fi fi-rr-globe" />
                        <div>
                            {`${me.associatedOrganizationName}`}
                        </div>
                    </div>
                    <>
                        <div className="py-2 px-4 flex hover:bg-gray-300">
                            <i className="mt-[2px] mr-4 fi fi-rr-exit" />
                            <div>
                                Logout
                            </div>
                        </div>
                    </>
                </PopoverContent>
            </Popover>
        </div>
    );
}
